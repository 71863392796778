<template>
  <div class="tabs">
    <span
      v-for="(tab, index) in tabList"
      :key="tab.value"
      tabindex="0"
      @click="switchTab(tab)"
      @keyup.enter="switchTab(tab)"
      :class="{ active: activeTab.value === tab.value, error: tab.error }"
    >
      {{ tab.label }}<span v-if="showIndex"> {{ index + 1 }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'base-tab-selector',
  props: {
    value: Object,
    tabList: {
      type: Array,
      default: () => [],
    },
    showIndex: Boolean,
  },
  data() {
    return {
      activeTab: null,
    };
  },
  created() {
    if (this.tabList.length && this.activeTab === null) {
      this.activeTab = this.tabList[0];
    }
  },
  watch: {
    tabList(newTabList) {
      this.activeTab = newTabList[0];
    },
    value: {
      handler(newActiveTab) {
        this.activeTab = newActiveTab;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    switchTab(tab) {
      this.activeTab = tab;
      this.$emit('input', tab);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  flex-wrap: wrap;
  font-family: $extended;
  font-size: 14px;
  > span {
    padding: 5px 0;
    margin-right: 30px;
    color: $grey--warm;
    cursor: pointer;
    &:hover {
      color: $grey--medium;
    }
    &.active {
      color: $grey--dark;
      border-bottom: 2px solid $grey--dark;
    }
    &.error {
      color: $error;
      border-color: $error;
    }
  }
}
</style>
