<template>
  <div class="dealer-assets">
    <Slider :sliderContent="sliderContent" showEdit />
    <div class="content">
      <h3>Dealer Submitted Assets</h3>
      <BaseTabSelector v-model="activeTab" :tabList="tabList" />
      <div class="card-container">
        <transition-group name="fade" mode="out-in">
          <CmsSubmittedAssetCard
            v-for="asset in activeAssets.rows"
            :key="asset.id"
            :asset="asset"
            @status-changed="refreshAssetList($event)"
          />
        </transition-group>
      </div>
      <!-- <span>Total Asset Count: {{ activeAssets.count }}</span> -->
      <AssetPagination :itemCount="activeAssets.count" :perPage="10" />
    </div>
  </div>
</template>

<script>
import Slider from '@/components/Slider.vue';
import CmsSubmittedAssetCard from '@/components/CmsSubmittedAssetCard.vue';
import ApiService from '@/services/api.service';
import BaseTabSelector from '@/components/BaseTabSelector.vue';
import AssetPagination from '@/components/AssetPagination.vue';

const PENDING_URL = '/admin/assets/pending';
const APPROVED_URL = '/admin/assets/approved';
const DENIED_URL = '/admin/assets/denied';

export default {
  name: 'cms-dealer-submitted-assets',
  components: { AssetPagination, BaseTabSelector, CmsSubmittedAssetCard, Slider },
  data() {
    return {
      isFirstRun: true,
      tabList: [
        { label: 'Pending', value: 'pending' },
        { label: 'Approved', value: 'approved' },
        { label: 'Denied', value: 'denied' },
      ],
      activeTab: this.getActiveTab(this.$route.path),
      pendingAssets: { rows: [], count: 0 },
      approvedAssets: { rows: [], count: 0 },
      deniedAssets: { rows: [], count: 0 },
      sliderContent: [],
      pendingPage: 1,
      approvedPage: 1,
      deniedPage: 1,
    };
  },
  computed: {
    activeAssets() {
      switch (this.activeTab.value) {
        case 'approved':
          return this.approvedAssets;
        case 'denied':
          return this.deniedAssets;
        default:
          return this.pendingAssets;
      }
    },
    activePageNumber() {
      switch (this.activeTab.value) {
        case 'approved':
          return this.approvedPage;
        case 'denied':
          return this.deniedPage;
        default:
          return this.pendingPage;
      }
    },
  },
  watch: {
    /**
     * Asset api calls when navigating to a new page of data.
     */
    '$route.params.page': {
      immediate: true,
      handler: function watchPage(newPage) {
        switch (this.activeTab.value) {
          case 'approved':
            this.approvedPage = newPage || 1;
            if (!this.isFirstRun) {
              ApiService.get(APPROVED_URL, { page: newPage - 1 }).then(({ data }) => {
                this.approvedAssets = data.data.approvedAssets;
              });
            }
            break;
          case 'denied':
            this.deniedPage = newPage || 1;
            if (!this.isFirstRun) {
              ApiService.get(DENIED_URL, { page: newPage - 1 }).then(({ data }) => {
                this.deniedAssets = data.data.deniedAssets;
              });
            }
            break;
          default:
            this.pendingPage = newPage || 1;
            if (!this.isFirstRun) {
              ApiService.get(PENDING_URL, { page: newPage - 1 }).then(({ data }) => {
                this.pendingAssets = data.data.pendingAssets;
              });
            }
            break;
        }
        this.isFirstRun = false;
      },
    },
    activeTab(newTab) {
      this.$router.push(`/portal/cms/dashboard/${newTab.value}/${this.activePageNumber}`);
    },
  },
  created() {
    Promise.allSettled([
      ApiService.get(PENDING_URL, { page: this.pendingPage - 1 }),
      ApiService.get(APPROVED_URL, { page: this.approvedPage - 1 }),
      ApiService.get(DENIED_URL, { page: this.deniedPage - 1 }),
      ApiService.get('/admin/slider/'),
    ]).then((results) => {
      this.pendingAssets = results[0].value?.data?.data?.pendingAssets;
      this.approvedAssets = results[1].value?.data?.data?.approvedAssets;
      this.deniedAssets = results[2].value?.data?.data?.deniedAssets;
      this.sliderContent = results[3].value?.data?.data?.slider;
    });
  },
  methods: {
    /**
     * Remove assets that have been moved to a new status and refresh the data
     */
    refreshAssetList({ oldAsset, newStatus }) {
      // Remove modified asset from current status list
      if (oldAsset.assetStatusId === 1) {
        this.pendingAssets.rows = this.pendingAssets.rows.filter((pendingAsset) => pendingAsset.id !== oldAsset.id);
        this.pendingAssets.count -= 1;
      } else if (oldAsset.assetStatusId === 2) {
        this.approvedAssets.rows = this.approvedAssets.rows.filter((approvedAsset) => approvedAsset.id !== oldAsset.id);
        this.approvedAssets.count -= 1;
      } else if (oldAsset.assetStatusId === 3) {
        this.deniedAssets.rows = this.deniedAssets.rows.filter((deniedAsset) => deniedAsset.id !== oldAsset.id);
        this.deniedAssets.count -= 1;
      }
      // Refresh data for asset list the asset is moved to
      if (newStatus === 2) {
        ApiService.get(APPROVED_URL, { page: this.approvedPage - 1 }).then(({ data }) => {
          this.approvedAssets = data.data.approvedAssets;
        });
      } else if (newStatus === 3) {
        ApiService.get(DENIED_URL, { page: this.deniedPage - 1 }).then(({ data }) => {
          this.deniedAssets = data.data.deniedAssets;
        });
      }
    },
    getActiveTab(path) {
      if (path.search('approved') !== -1) {
        return { value: 'approved' };
      }
      if (path.search('denied') !== -1) {
        return { value: 'denied' };
      }
      return { value: 'pending' };
    },
  },
};
</script>

<style lang="scss" scoped>
.dealer-assets {
  .content {
    padding: 40px;
    h3 {
      font-size: 16px;
    }
    .tabs {
      margin-top: 35px;
    }
    .card-container {
      display: flex;
      flex-direction: column;
      padding: 30px 0;
      .asset-card:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
