<template>
  <div class="asset-card">
    <div class="left-col">
      <div class="img-container">
        <img
          :src="asset.assetType.defaultImage || asset.downloadLink"
          :class="asset.assetType.name"
          :alt="asset.name"
        />
      </div>
      <CaratButton @input="previewAsset" label="Preview Asset" />
    </div>
    <form class="text-container" @submit.prevent="submit">
      <h4>{{ asset.name }}</h4>
      <div class="tags">
        <span>{{ asset.assetType.name }}</span>
        <span>{{ asset.assetModelYear.modelName }}</span>
        <span>{{ asset.assetModelYear.modelYear }}</span>
      </div>
      <table>
        <tbody>
          <tr>
            <td class="label">Job Role</td>
            <td>{{ getCommaSeparatedList(asset.assetJobRoleCategories) }}</td>
          </tr>
          <tr>
            <td class="label">Asset Category</td>
            <td>{{ getCommaSeparatedList(asset.assetCategories) }}</td>
          </tr>
          <tr>
            <td class="label">Submitted By</td>
            <td>{{ submittedBy }}</td>
          </tr>
          <tr>
            <td class="label">Description</td>
            <td>{{ asset.description }}</td>
          </tr>
          <tr>
            <td class="label">Sharing</td>
            <td>
              <input
                class="form-check-input"
                type="checkbox"
                v-model="assetSubmission.isPublic"
                @change.prevent="submit"
              />
            </td>
          </tr>
          <tr>
            <td class="label">Downloading</td>
            <td>
              <input
                class="form-check-input"
                type="checkbox"
                v-model="assetSubmission.isDownloadEnabled"
                @change.prevent="submit"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="submission-container">
        <input
          :disabled="asset.assetStatusId === 2"
          @click="assetSubmission.assetStatusId = 2"
          type="submit"
          value="Approve & Publish"
          class="btn-primary"
        />
        <input
          :disabled="asset.assetStatusId === 3"
          @click="assetSubmission.assetStatusId = 3"
          type="submit"
          value="Deny & Remove"
          class="btn-outline-primary"
        />
      </div>
    </form>

    <!-- Asset Preview Video Player Modal -->
    <BaseModal ref="videoModal" :modalType="'video'" @modal-close="videoModalClosed">
      <template v-slot:modal-content>
        <div class="video">
          <VuePlyr ref="videoPlyr" :options="videoOptions">
            <video controls>
              <source :src="asset.downloadLink" type="video/mp4" />
            </video>
          </VuePlyr>
        </div>
      </template>
    </BaseModal>

    <!-- Asset Preview MP3 Modal -->
    <BaseModal ref="mp3Modal" :modalType="'mp3'" @modal-close="mp3ModalClosed">
      <template v-slot:modal-content>
        <div class="mp3">
          <VuePlyr ref="mp3Plyr" :options="mp3Options">
            <audio controls controlsList="nodownload" playsinline>
              <source :src="asset.downloadLink" type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </VuePlyr>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import ApiService from '@/services/api.service';
import CaratButton from '@/components/CaratButton.vue';

export default {
  name: 'submitted-asset-card',
  components: {
    BaseModal: () => import(/* webpackChunkName: "BaseModal" */ '../components/BaseModal.vue'),
    VuePlyr: () => import(/* webpackChunkName: "VuePlyr" */ 'vue-plyr'),
    CaratButton,
  },
  props: {
    asset: Object,
  },
  data() {
    return {
      assetSubmission: {},
      videoOptions: {
        controls: `
          <div class="plyr__controls">
            <button type="button" class="plyr__control icon-play" aria-label="Play, {title}" data-plyr="play">
              <svg class="icon--pressed" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect class="cls-1" x="19" y="15" width="1" height="15"/><path class="cls-1" d="M22.5,0A22.5,22.5,0,1,0,45,22.5,22.52,22.52,0,0,0,22.5,0Zm0,44A21.5,21.5,0,1,1,44,22.5,21.53,21.53,0,0,1,22.5,44Z"/><rect class="cls-1" x="25" y="15" width="1" height="15"/></g></g></svg>
              <svg class="icon--not-pressed" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M22.5,0A22.5,22.5,0,1,0,45,22.5,22.52,22.52,0,0,0,22.5,0Zm0,44A21.5,21.5,0,1,1,44,22.5,21.53,21.53,0,0,1,22.5,44Z"/><path class="cls-1" d="M18,30.55l11.79-8.16L18,14.46Zm1-14.21,9,6.07-9,6.24Z"/></g></g></svg>
              <span class="label--pressed plyr__tooltip" role="tooltip">Pause</span>
              <span class="label--not-pressed plyr__tooltip" role="tooltip">Play</span>
            </button>
            <div class="plyr__progress">
                <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
                <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
                <span role="tooltip" class="plyr__tooltip">00:00</span>
            </div>
            <div class="plyr__time-container">
            <div class="plyr__time plyr__time--current" aria-label="Current time">00:00</div>
            <div class="plyr__time plyr__time--duration" aria-label="Duration">00:00</div>
            </div>
            <div class="mobile-break"></div>
            <div class="plyr__volume-container">
              <button type="button" class="plyr__control" aria-label="Mute" data-plyr="mute">
                  <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-muted"></use></svg>
                  <svg class="icon--not-pressed" role="presentation" xmlns="http://www.w3.org/2000/svg" width="20.018" height="18.459" viewBox="0 0 20.018 18.459">
                    <g id="volume_icon" transform="translate(-5 17.434)">
                      <g id="Group_2942" data-name="Group 2942" transform="translate(5.5 -16.5)">
                        <path id="Path_26288" data-name="Path 26288" d="M5,11.207h6.31V19.62H5Zm6.31,8.413,6.31,4V7l-6.31,4.207m9.886-.841s6.31,3.786,0,10.1" transform="translate(-5 -7)" fill="none" stroke="#666" stroke-width="1"/>
                      </g>
                    </g>
                  </svg>
                  <span class="label--pressed plyr__tooltip" role="tooltip">Unmute</span>
                  <span class="label--not-pressed plyr__tooltip" role="tooltip">Mute</span>
              </button>
              <div class="plyr__volume">
                  <input data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autocomplete="off" aria-label="Volume">
              </div>
            </div>
          </div>`,
        settings: ['captions'],
        displayDuration: true,
        toggleInvert: false,
        volume: 1,
        keyboard: {
          focused: false,
          global: false,
        },
        hideControls: false,
      },
      mp3Options: {
        controls: `
          <div class="plyr__controls">
            <button type="button" class="plyr__control icon-play" aria-label="Play, {title}" data-plyr="play">
              <svg class="icon--pressed" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect class="cls-1" x="19" y="15" width="1" height="15"/><path class="cls-1" d="M22.5,0A22.5,22.5,0,1,0,45,22.5,22.52,22.52,0,0,0,22.5,0Zm0,44A21.5,21.5,0,1,1,44,22.5,21.53,21.53,0,0,1,22.5,44Z"/><rect class="cls-1" x="25" y="15" width="1" height="15"/></g></g></svg>
              <svg class="icon--not-pressed" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M22.5,0A22.5,22.5,0,1,0,45,22.5,22.52,22.52,0,0,0,22.5,0Zm0,44A21.5,21.5,0,1,1,44,22.5,21.53,21.53,0,0,1,22.5,44Z"/><path class="cls-1" d="M18,30.55l11.79-8.16L18,14.46Zm1-14.21,9,6.07-9,6.24Z"/></g></g></svg>
              <span class="label--pressed plyr__tooltip" role="tooltip">Pause</span>
              <span class="label--not-pressed plyr__tooltip" role="tooltip">Play</span>
            </button>
            <div class="plyr__info-container">
              <div class="plyr__info">{title}</div>
              <div class="plyr__progress">
                <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
                <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
                <span role="tooltip" class="plyr__tooltip">00:00</span>
              </div>
              <div class="plyr__time-container">
                <div class="plyr__time plyr__time--current" aria-label="Current time">00:00</div>
                <div class="plyr__time plyr__time--duration" aria-label="Duration">00:00</div>
              </div>
            </div>
          </div>`,
        settings: ['captions'],
        displayDuration: true,
        toggleInvert: false,
        volume: 1,
        keyboard: {
          focused: false,
          global: false,
        },
        hideControls: false,
        title: this.name,
      },
    };
  },
  computed: {
    submittedBy() {
      const user = this.asset.assetUser;
      return `${user.firstName} ${user.lastName}, ${user.dealershipName}`;
    },
  },
  created() {
    this.assetSubmission.assetStatusId = this.asset.assetStatusId;
    this.assetSubmission.isPublic = this.asset.isPublic;
    this.assetSubmission.isDownloadEnabled = this.asset.isDownloadEnabled;
  },
  methods: {
    getCommaSeparatedList(list) {
      let concatList = '';
      list.forEach((item, index) => {
        concatList += item.name;
        if (list.length !== index + 1) {
          concatList += ', ';
        }
      });
      return concatList;
    },
    submit() {
      // Convert to binary
      this.assetSubmission.isDownloadEnabled = this.assetSubmission.isDownloadEnabled ? 1 : 0;
      this.assetSubmission.isPublic = this.assetSubmission.isPublic ? 1 : 0;
      ApiService.patch(`/admin/assets/${this.asset.id}/status`, this.assetSubmission).then(() => {
        if (this.assetSubmission.assetStatusId !== this.asset.assetStatusId) {
          this.$emit('status-changed', { oldAsset: this.asset, newStatus: this.assetSubmission.assetStatusId });
        }
      });
    },
    previewAsset() {
      switch (this.asset.assetType.name) {
        case 'Video':
          this.openVideoModal();
          break;
        case 'MP3':
          this.openMp3Modal();
          break;
        default:
          // Image & PDF
          window.open(this.asset.downloadLink, '_blank');
          break;
      }
    },
    openVideoModal() {
      this.$refs.videoModal.openModal();
    },
    videoModalClosed() {
      this.$refs.videoPlyr.player.stop();
    },
    openMp3Modal() {
      this.$refs.mp3Modal.openModal();
    },
    mp3ModalClosed() {
      this.$refs.mp3Plyr.player.stop();
    },
  },
};
</script>

<style lang="scss" scoped>
.asset-card {
  display: flex;
  padding: 30px;
  background: $grey-background;
  .left-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      min-width: 150px;
      height: 84px;
      background: $grey--dark;
      img {
        height: 35px;
        color: $white;
        &.Image {
          width: 150px;
          height: 84px;
          object-fit: cover;
          object-position: center;
        }
        &.PDF {
          width: 29px;
          height: 36px;
        }
        &.Video {
          width: 50px;
          height: 30px;
        }
        &.MP3 {
          height: 33px;
        }
      }
    }
    .edit-btn {
      display: flex;
      align-items: center;
      margin-top: 15px;
      font-family: $wide;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        opacity: 0.75;
      }
      svg {
        height: 8px;
        margin-left: 5px;
        transition: transform 200ms ease;
        &.down {
          transform: rotate(90deg);
        }
        .stroke {
          stroke: $grey--dark;
        }
      }
    }
  }
  .text-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .tags {
      span {
        padding: 5px 10px;
        margin-right: 10px;
        font-family: $wide;
        font-size: 10px;
        color: $grey--dark;
        background: $grey--soft;
      }
    }

    margin-left: 30px;
    table {
      margin: 20px 0 30px 0;
      font-size: 12px;
      td {
        padding: 5px 0;
        &.label {
          width: 125px;
          font-family: $wide;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
    .submission-container {
      display: flex;
      justify-content: flex-end;
      input {
        width: 209px;
        height: 56px;
        font-family: $wide;
        font-size: 16px;
        &.btn-outline-primary {
          border-width: 1px;
        }
        &:first-child {
          margin-right: 10px;
        }
        &:disabled {
          color: $white;
          background: $disabled;
          border: none;
        }
      }
    }
  }
}
.video {
  ::v-deep {
    video {
      width: 100%;
    }
    .mobile-break {
      flex-basis: 100%;
      height: 0;
      @include bp-md-tablet {
        display: none;
      }
    }
    .plyr--video .plyr__control.plyr__tab-focus,
    .plyr--video .plyr__control:hover,
    .plyr--video .plyr__control[aria-expanded='true'] {
      color: $grey--dark;
      background-color: transparent;
    }
    .plyr--video .plyr__controls {
      position: relative;
      flex-wrap: wrap;
      justify-content: flex-start;
      order: 2;
      width: 100%;
      padding: 0.9375rem; // 15px
    }
    .icon-play {
      padding: 0 0.9375rem 0 0; // 15px
      svg {
        width: 2.8125rem; // 45px
        height: 2.8125rem; // 45px
      }
    }
    .plyr__progress {
      width: calc(100% - 80px);
      @include bp-md-tablet {
        flex-grow: 1;
        order: 2;
        width: auto;
      }
    }
    .icon-volume svg {
      width: 1.1875rem; // 19px
      height: 1rem; // 16px
    }
    .plyr__time + .plyr__time {
      display: block;
      &::before {
        margin-right: 5px;
        margin-left: 5px;
        content: '|';
      }
    }
    .plyr__time--current {
      color: $black;
    }
    .plyr__time-container {
      display: flex;
      margin-left: 60px;
      @include bp-md-tablet {
        margin-right: 0.9375rem; // 15px
        margin-left: 0;
      }
    }
    .plyr__volume-container {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      .plyr__volume {
        width: 100px;
        min-width: 100px;
      }
      @include bp-md-tablet {
        order: 3;
        margin-left: 2.5rem; // 40px
      }
    }
  }
}
.mp3 {
  ::v-deep {
    audio {
      width: 100%;
    }
    .plyr--audio .plyr__control.plyr__tab-focus,
    .plyr--audio .plyr__control:hover,
    .plyr--audio .plyr__control[aria-expanded='true'] {
      color: $grey--dark;
      background-color: transparent;
    }
    .plyr__controls {
      justify-content: flex-start;
      width: 100%;
    }
    .icon-play {
      padding: 0 0.9375rem 0 0; // 15px
      svg {
        width: 2.8125rem; // 45px
        height: 2.8125rem; // 45px
      }
    }
    .plyr__info-container {
      flex-grow: 1;
      text-align: left;
    }
    .plyr__time + .plyr__time {
      display: block;
      &::before {
        margin-right: 5px;
        margin-left: 5px;
        content: '|';
      }
    }
    .plyr__time-container {
      display: flex;
    }
  }
}
</style>
